<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    edit-page-router="admin-search"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "admin-searches",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/searches",
      deleteUrl: "api/admin/search/delete/",

      editPage: "/admin-dashboard/search/",
      title: "Лог поисковых запросов",
      columns: [
        {
          name: "Date",
          value: "createdDate",
          processor: function (dt) {
            let lang = localStorage.getItem("lang")
              ? localStorage.getItem("lang")
              : "en";

            const formatter = new Intl.DateTimeFormat(lang, {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            });
            return formatter.format(new Date(dt));
          },
        },
        {
          name: "Query",
          value: "query",
        },
        {
          name: "IP Address",
          value: "ipAddress",
        },
        {
          name: "Search Type",
          value: "searchType",
        },
      ],
    };
  },

  methods: {},
});
</script>
